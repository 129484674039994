import { Button, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import api from '../../api'
import ConfirmModal from '../../components/ConfirmModal'
import CustomIcon from '../../components/icons/CustomIcon'
import { UIContext } from '../../layout/UIContext'
import { convertErrorToText, validateEmail } from '../../utils'

const AddMemberModal = ({
    organizationId,
    refetchData,
}: {
    organizationId: number
    refetchData: () => Promise<void>
}) => {
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')
    const { dispatch } = useContext(UIContext)

    const isValidEmail = validateEmail(email)

    const handleClose = () => setOpen(false)
    const addMember = async () => {
        try {
            dispatch({ type: 'SHOW_LOADING' })
            await api.addMember(organizationId, email)
            refetchData()
            setEmail('')
            handleClose()
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Add member successfully!',
                    type: 'success',
                },
            })
        } catch (err) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: convertErrorToText(err),
                    type: 'error',
                },
            })
        } finally {
            dispatch({ type: 'HIDE_LOADING' })
        }
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                startIcon={<CustomIcon icon="plus" sx={{ color: 'white', fontSize: '16px' }} />}
                sx={{ fontSize: '10px' }}
                onClick={() => setOpen(true)}
            >
                Add new member
            </Button>
            <ConfirmModal
                title="Add new member"
                body={
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ width: 400 }}
                        error={!!email && !isValidEmail}
                        helperText={!!email && !isValidEmail && 'Invalid email'}
                    />
                }
                open={open}
                onClose={handleClose}
                onConfirm={addMember}
                disableSubmit={!isValidEmail}
            />
        </>
    )
}

export default AddMemberModal

import React, { useContext } from 'react'
import api from '../../api'
import ConfirmModal from '../../components/ConfirmModal'
import { UIContext } from '../../layout/UIContext'
import { convertErrorToText } from '../../utils'

interface Props {
    open: boolean
    handleClose: () => void
    organizationId: number
    memberId: number
    refetchData?: () => Promise<void>

}

const DeleteDeviceModal = ({
    open,
    handleClose,
    organizationId,
    memberId,
    refetchData,
}: Props) => {
    const {dispatch} = useContext(UIContext)
    const removeMember = async () => {
        try {
            await api.removeMember(organizationId, memberId)
            refetchData?.()
            handleClose()
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Removed member',
                    type: 'success'
                }
            })
        } catch (err) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: convertErrorToText(err),
                    type: 'error'
                }
            })
        }
        
    }

    return (
        <ConfirmModal
            title="Remove member"
            body="Are you sure to remove this member from organization?"
            onClose={handleClose}
            open={open}
            onConfirm={removeMember}
        />
    )
}

export default DeleteDeviceModal

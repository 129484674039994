import { Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useKeyPress from '../hooks/useKeyPress'
import CustomIcon from './icons/CustomIcon'

interface Props {
    value: string
    onChange: (value: string) => void
    onSave: () => void
    onBlur?: () => void
    placeholder?: string
}

const EditableTextField = ({ value, onChange, onBlur, onSave, placeholder }: Props) => {
    const escPress = useKeyPress('Escape')
    const [isEditing, setIsEditing] = useState(false)
    const handleSave = () => {
        onSave()
        setIsEditing(false)
    }

    useEffect(() => {
        if (escPress) {
            setIsEditing(false)
        }
    }, [escPress])

    return (
        <div>
            {!isEditing ? (
                <Typography variant="subtitle1" component="span">
                    {placeholder && !value ? placeholder : value}
                </Typography>
            ) : (
                <TextField
                    variant="standard"
                    autoFocus
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur || handleSave}
                />
            )}
            {isEditing ? (
                <Button sx={{ ml: 2 }} onClick={handleSave} size="small" variant="outlined">
                    Save
                </Button>
            ) : (
                <IconButton sx={{ ml: 2, mt: -1 }} onClick={() => setIsEditing(true)}>
                    <CustomIcon icon="edit" color="primary" sx={{ fontSize: 18 }} />
                </IconButton>
            )}
        </div>
    )
}

export default EditableTextField

import { Container, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { AuthContext } from '../auth/AuthContext'

const NoOrganization = () => {
    const navigate = useNavigate()
    const { state } = useContext(AuthContext)

    if (state.user?.isSuperuser) {
        navigate('/organizations')
        return null
    }

    if (state.organization) {
        navigate('/devices')
        return null
    }

    return (
        <Container className="flex-center" sx={{height: `calc(100vh - 100px)`}}>
            <Typography variant="caption" sx={{fontSize: 30, fontWeight: 700, color: grey[800]}}>
                Not authorized
            </Typography>
        </Container>
    )
}

export default NoOrganization

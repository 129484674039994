import { Grid, Paper, Typography } from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import React from 'react'
import DeviceStatus from '../../components/DeviceStatus'
import { Device } from '../../type'
import { getBatteryColor } from '../../utils'

const DeviceOverview = ({ device }: { device: Device }) => {
    return (
        <Paper sx={{ mt: 2, px: 2, py: 1, background: lightBlue[50] }} elevation={0}>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ borderRight: {md: '1px solid rgba(0, 0, 0, 0.2)'}}}>
                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>IMEI:</strong> {device.simId}
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>Online status:</strong> <DeviceStatus device={device} />
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>Current signal quality:</strong> {device.isOnline ? device.lastSignalQuality : 0}
                    </Typography>

                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>Device note:</strong> {device.note}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ pl: {md: 2} }}>
                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>Alert:</strong>
                        <Typography
                            sx={{ fontSize: '14px', ml: 1, fontWeight: 700 }}
                            color={device.enableAlerts ? 'success.main' : 'error.main'}
                            component="span"
                        >
                            {device.enableAlerts ? 'Enabled' : 'Disabled'}
                        </Typography>
                    </Typography>
                    <Typography sx={{ fontSize: '14px', display: 'flex' }}>
                        <strong>Battery: </strong>
                        <Typography sx={{
                            color: getBatteryColor(device.batteryLevel || 0),
                            fontSize: '14px',
                            fontWeight: 700,
                            ml: 1
                        }}>
                            {device.batteryLevel}%
                        </Typography>
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>Connected Status</strong> {
                        device.powerStatus || (
                            device.connectedToPower
                                ? 'Connected to Charger'
                                : 'Disconnected from Charger'
                        )
                    }
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                        <strong>Battery Voltage</strong> {
                        device.connectedToPower
                        ? `${device.powerVolt}v DC` || ''
                        : ''
                    }
                    </Typography>
                    <Typography sx={{ fontSize: '14px'}}>
                        <strong>Version:</strong> {device.version}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default DeviceOverview

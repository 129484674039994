import styled from '@emotion/styled'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import { blue } from '@mui/material/colors'
import Toolbar from '@mui/material/Toolbar'
import { Box } from '@mui/system'
import * as React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.png'
import { AuthContext } from '../auth/AuthContext'
import RequestLogsDialog from '../features/device/RequestLogs'

const S = {
    AppBar: styled(AppBar)`
        height: 50px;
        background: white;
        & .MuiToolbar-root {
            min-height: 50px;
        }
    `,
    Logo: styled.img`
        height: 46px;
        margin: 0 24px;
    `,
    NavItem: styled(NavLink)`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        padding: 0 20px;
        color: ${blue[800]};
        &.active {
            background-color: #edf8fc;
            border-bottom: 2px solid ${blue[800]};
            border-top: 2px solid transparent;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    `,
}

export default function TopNav() {
    const { state, dispatch } = React.useContext(AuthContext)
    const navigate = useNavigate()

    const handleLogout = () => {
        dispatch({ type: 'LOGOUT' })
        navigate('/')
    }

    return (
        <S.AppBar position="static">
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <S.Logo src={Logo} />
                    {state.user?.isSuperuser && <S.NavItem to="/organizations">Organizations</S.NavItem>}
                    {(state.organization || state.user?.isSuperuser) && (
                        <S.NavItem to="/devices">Devices</S.NavItem>
                    )}
                </Box>
                {state.isAuthenticated && (
                    <>
                        {state.user?.isSuperuser && <RequestLogsDialog />}
                        <Button color="info" onClick={handleLogout} sx={{ml: 2}}>
                            Log out
                        </Button>
                    </>
                )}
            </Toolbar>
        </S.AppBar>
    )
}

import styled from '@emotion/styled'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import DatePicker from '@mui/lab/DatePicker'
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
// import { TextField } from '@mui/material'
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import 'chartjs-adapter-date-fns/dist/chartjs-adapter-date-fns'
import { RealTimeScale, StreamingPlugin } from 'chartjs-plugin-streaming'
import zoomPlugin from 'chartjs-plugin-zoom'
import { isToday } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useParams } from 'react-router'
import api from '../../api'
import { DEVICE_DETAIL_INTERVAL } from '../../constants'
import { HOUR, MINUTE, SECOND } from '../../constants/common'
import { useAsync } from '../../hooks'
import { Device } from '../../type'
import { convertMessageToChartData, createPointFromDeviceInfo, handeOffSignalData } from '../../utils'
import DeviceOverview from './DeviceOverview'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    StreamingPlugin,
    RealTimeScale,
    zoomPlugin
)

const pluginConfig = {
    zoom: {
        pan: {
            enabled: true,
            mode: 'x',
        },
        zoom: {
            wheel: {
                enabled: true,
                mode: 'x',
            },
            enabled: true,
            mode: 'x',
        },
        limits: {
            x: { min: 'original', max: 'original', minRange: 30 * MINUTE },
        },
    },
    legend: {
        display: false,
    },
    streaming: {
        frameRate: 1,
    },
}

const yAxisConfig = {
    min: 0,
    grid: {
        color: (context: any) => {
            if (context.tick.value === 15) {
                return 'orange'
            }
            if (context.tick.value === 5) {
                return 'red'
            }
            return 'rgba(255, 255, 255, 0.2)'
        },
        borderDash: [4, 8],
        lineWidth: 0.75,
        borderColor: 'white',
    },
}

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone // Get timezone from browser

const options = {
    responsive: true,
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'second',
                timezone: browserTimezone,
                stepSize: 20 * 60,
            },
            grid: {
                color: 'rgba(255, 255, 255, 0.5)',
                lineWidth: 0.3,
                borderColor: 'white',
            },
        },
        y: yAxisConfig,
    },
    plugins: pluginConfig,
}

const dataConfig = (data: any) => ({
    datasets: [
        {
            data,
            borderWidth: 2.5,
            borderColor: 'green',
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
            pointRadius: 1,
            pointBackgroundColor: function (context: any) {
                return context.parsed?.y <= 15 ? context.parsed?.y <= 5 ? 'red' : 'orange' : 'green'
            },
            pointBorderColor: function (context: any) {
                return context.parsed?.y <= 15 ? context.parsed?.y <= 5 ? 'red' : 'orange' : 'green'
            },
            segment: {
                borderColor: (ctx: any) => (ctx.p0.parsed?.y <= 5 && ctx.p1.parsed?.y <= 5 ? 'red' : 'green'),
            },
        },
    ],
})

const S = {
    ChartWrapper: styled.div`
        position: relative;
        margin: 8px 0 24px 0;
        & canvas {
            background-color: #161A1E;
        }
    `,
    FilterWraper: styled.div`
        position: absolute;
        background-color: white;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 12px;
    `,
}

export function DeviceStatusChart() {
    const { deviceId } = useParams()
    const [date, setDate] = useState(new Date())
    const [chartType, setChartType] = useState('realtime')
    const [device, setDevice] = useState<Device | null>(null)
    const fetchDeviceMessages = useCallback(() => api.getDeviceMessages(deviceId, {limit: 30000, chart_date: date.toISOString()}), [deviceId, date])
    const { value: messagesResponse } = useAsync(fetchDeviceMessages)

    const messages = useMemo(
        () =>
            handeOffSignalData(
                convertMessageToChartData(messagesResponse?.results || []),
                chartType === 'realtime'
            ),
        [messagesResponse?.results, chartType]
    )

    const onRefresh = useCallback(async (chart: any) => {
        const _device = await api.getDeviceDetail(deviceId)
        setDevice(_device)
        chart.data.datasets.forEach(function (dataset: any) {
            dataset.data.push(createPointFromDeviceInfo(_device))
        })
    }, [])

    const realtimeOptions = useMemo(
        () => ({
            responsive: true,
            scales: {
                x: {
                    type: 'realtime',
                    realtime: {
                        duration: 10 * MINUTE,
                        ttl: 24 * HOUR,
                        refresh: DEVICE_DETAIL_INTERVAL,
                        delay: 2 * SECOND,
                        onRefresh: onRefresh,
                    },
                    time: {
                        unit: 'second',
                        timezone: browserTimezone,
                        stepSize: 10 * 60,
                    },
                    grid: {
                        color: 'rgba(255, 255, 255, 0.5)',
                        lineWidth: 0.3,
                        borderColor: 'white',
                    },
                },
                y: yAxisConfig,
            },
            plugins: pluginConfig,
        }),
        [onRefresh]
    )

    useEffect(() => {
        if (isToday(date)) {
            setChartType('realtime')
            return
        }
        setChartType('time')
    }, [date])

    return (
        <>
            {device && <DeviceOverview device={device} />}
            <S.ChartWrapper id="status-graph">
                {/* <S.FilterWraper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Filter date"
                            value={date}
                            onChange={(newValue) => {
                                // @ts-ignore
                                setDate(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" size="small" />
                            )}
                            disableFuture
                        />
                    </LocalizationProvider>
                </S.FilterWraper> */}
                {chartType === 'realtime' ? (
                    // @ts-ignore
                    <Line options={realtimeOptions} data={dataConfig(messages)} key="realtime-chart" />
                ) : (
                    // @ts-ignore
                    <Line options={options} data={dataConfig(messages)} key="history-chart" />
                )}
            </S.ChartWrapper>
        </>
    )
}

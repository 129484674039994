import styled from '@emotion/styled'
import React, { createRef, useContext, useLayoutEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import auth from '../auth/auth'
import { AuthContext } from '../auth/AuthContext'
import { validateEmail } from '../utils'

const S = {
    Wrapper: styled.div`
        z-index: 99998;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        background-color: rgba(0, 0, 0, 0.34);
    `,
    CenterForm: styled.div`
        z-index: 99999;
        margin: auto;
    `,
}

const CreateAccount = ({isResettingPassword}: {isResettingPassword?: boolean}) => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const sha = searchParams.get('sha')
    const widgetRef = createRef()
    const { dispatch } = useContext(AuthContext)

    const close = () => {}

    const login = (event: any) => {
        const jwt = event.detail
        auth.storeTokens(jwt)
        dispatch({ type: 'LOGIN' })
        navigate('/devices')
    }

    useLayoutEffect(() => {
        // @ts-ignore
        widgetRef?.current?.addEventListener?.('callback', login)
    })

    return (
        <S.Wrapper onClick={close}>
            <S.CenterForm onClick={(e: any) => e?.stopPropagation?.()}>
                {/* @ts-ignore */}
                <digitpol-auth-widget ref={widgetRef} sha={sha} isResettingPassword={isResettingPassword} />
            </S.CenterForm>
        </S.Wrapper>
    )
}

export default CreateAccount

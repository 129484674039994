import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import TopNav from './TopNav'
import UIProvider from './UIContext'

const S = {
    Wrapper: styled.div`
        width: 100%;
        min-height: 100vh;
    `
}

const Layout = ({ children }: {children: ReactNode}) => {
    return (
        <UIProvider>
            <S.Wrapper>
                <TopNav />
                {children}
            </S.Wrapper>
        </UIProvider>
    )
}

export default Layout

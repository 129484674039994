import { IconProps, SvgIcon } from '@mui/material'
import React from 'react'
import { IconPath, iconPaths } from './iconPaths'

const CustomIcon = ({icon, color ="action", ...props}: IconProps & {icon: IconPath}) => {
    return (
        // @ts-ignore
        <SvgIcon {...props} color={color}>
            <path d={iconPaths[icon]} />
        </SvgIcon>
    )
}

export default CustomIcon

import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react'

const UpdateCountDown = () => {
    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 7 ? 0 : prevProgress + 1));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Typography variant="overline" component="div" color="text.secondary" sx={{ mt: 2 }}>
            Last updated: <strong>{progress}</strong> <small>seconds ago</small>
        </Typography>
    )
}

export default UpdateCountDown

import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CustomIcon from '../../components/icons/CustomIcon'
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material'
import api from '../../api'
import { DATETIME_FORMAT, DEVICE_DETAIL_INTERVAL } from '../../constants'
import { useAsync } from '../../hooks'
import { format } from 'date-fns'
import SelectInput from '../../components/SelectInput'
import { Box } from '@mui/system'

export default function RequestLogsDialog({deviceId = ''}: {deviceId?: string | number}) {
    const [open, setOpen] = React.useState(false)
    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(10)
    const [pathFilter, setPathFilter] = React.useState('')

    const fetchRequestLogs = React.useCallback(
        () => api.getRequestLogs({
            limit: pageSize, offset: page * pageSize, device_id: deviceId, path_filter: pathFilter
        }),
        [page, pageSize, deviceId, pathFilter]
    )
    const { value, execute } = useAsync(fetchRequestLogs, true, !open)
    const logs = value?.results || []

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            execute()
        }, DEVICE_DETAIL_INTERVAL*5)

        return () => clearInterval(intervalId)
    }, [execute])


    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <div>
            <Button variant={deviceId ? 'contained' : 'outlined'} color="warning" size="small" onClick={handleClickOpen}>
                {deviceId ? 'Device request logs' : 'All request logs'}
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{ marginLeft: 'auto' }}
                        >
                            <CustomIcon icon="close" style={{ color: 'white' }} fontSize="large" />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="xl">
                    <Typography variant="h4" sx={{ mt: 3 }}>
                        {deviceId ? 'Device request logs' : 'All request logs'}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <SelectInput
                            label="Path filter"
                            options={[
                                {label: 'connections/', value: 'connections/'},
                                {label: 'message/', value: 'message/'},
                                {label: 'towers/', value: 'towers/'},
                            ]}
                            value={pathFilter}
                            onChange={setPathFilter}
                        />
                    </Box>
                    
                    <TableContainer component={Paper} sx={{ mt: 1 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Remote address</TableCell>
                                    <TableCell>Request path</TableCell>
                                    <TableCell>Request data</TableCell>
                                    <TableCell>Status code</TableCell>
                                    <TableCell>Response</TableCell>
                                    <TableCell>Device's sim id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs?.map((log) => (
                                    <TableRow key={log.id}>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                            {format(new Date(log.created), DATETIME_FORMAT)}
                                        </TableCell>
                         
                                        <TableCell>{log.remoteAddress}</TableCell>
                                        <TableCell>{log.requestPath}</TableCell>
                                        <TableCell>{log.requestData}</TableCell>
                                        <TableCell
                                            sx={{
                                                fontWeight: 700,
                                                color: log.statusCode?.includes('20') ? 'success.main' : 'error.main'
                                            }}
                                        >{log.statusCode}</TableCell>
                                        <TableCell>{JSON.stringify(log.response)}</TableCell>
                                        <TableCell>{log.deviceSimId}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={value?.count || 0}
                            page={page}
                            onPageChange={(e, page) => setPage(page)}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Container>
            </Dialog>
        </div>
    )
}

import { Typography } from '@mui/material'
import { DataGrid, gridClasses, GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import api from '../../api'
import ExportDialog from '../../components/ExportDialog'
import { DATETIME_FORMAT, DEVICE_DETAIL_INTERVAL } from '../../constants'
import { useAsync } from '../../hooks'

const CustomToolbar = ({ id }: { id: string }) => {
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <ExportDialog
                id={id}
                fetchData={api.getDeviceMessages}
                filename="messages"
                fields={['created', 'signalQuality']}
                headers={['Date', 'Signal Quality']}
            />
        </GridToolbarContainer>
    )
}

const MessageTable = ({ deviceId }: { deviceId?: string }) => {
    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(10)

    const fetchDeviceMessages = useCallback(
        () => api.getDeviceMessages(deviceId, { limit: pageSize, offset: page * pageSize }),
        [deviceId, page, pageSize]
    )
    const { value: messagesResponse, status, execute } = useAsync(fetchDeviceMessages)
    const messages = messagesResponse?.results || []

    useEffect(() => {
        const intervalId = setInterval(() => {
            execute()
        }, DEVICE_DETAIL_INTERVAL)

        return () => clearInterval(intervalId)
    }, [execute])

    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        {
            field: 'created',
            headerName: 'Date',
            flex: 1,
            sortable: false,
            valueFormatter: (params) => format(new Date(params.value as string), DATETIME_FORMAT),
        },
        { field: 'signalQuality', headerName: 'Signal quality', flex: 1, sortable: false },
    ]

    return (
        <>
            <Typography variant="h6" p={1}>
                Signal Timestamp
            </Typography>
            <div style={{ height: 'calc(100% - 148px)', minHeight: 350 }}>
                <DataGrid
                    rowHeight={32}
                    disableColumnMenu
                    columns={columns}
                    rows={messages}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={messagesResponse?.count}
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    loading={status === 'pending'}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: { id: deviceId },
                    }}
                />
            </div>
        </>
    )
}

export default MessageTable

import React from 'react'
import styled from '@emotion/styled'
import { green, orange, red, grey } from '@mui/material/colors'
import Tooltip from '@mui/material/Tooltip'

type SignalStatusType = 'Offline' | 'Very low' | 'Low' | 'Online'

function convertSignalStatus(signalQuality: number, isOnline: boolean): SignalStatusType {
    if (!isOnline) {
        return 'Offline'
    }
    if (signalQuality > 15) {
        return 'Online'
    }
    if (signalQuality >= 5) {
        return 'Low'
    }
    return 'Very low'
}

const SignalStatus = styled.div<{ status: SignalStatusType, isOnline: boolean }>`
    display: flex;
    align-items: flex-end;
    gap: 3px;
    position: relative;
    &:after {
        content: 'X';
        display: ${({ isOnline }) => isOnline ? 'none' : 'block'};
        position: absolute;
        color: ${red[500]};
        font-size: 12px;
        font-weight: bold;
        top: 2px;
        left: 20px;
    }
    & > div {
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background-color: ${({ status }) => (status === 'Online' ? `${green[500]} !important` : grey[300])};
    }
    & div:first-of-type {
        height: 6px;
        background-color: ${({ status }) => (status === 'Low' ? orange[500] : status === 'Very low' ? red[500] : grey[300])};
    }
    & div:nth-of-type(2) {
        height: 12px;
        background-color: ${({ status }) => (status === 'Low' ? orange[500] : grey[300])};
    }
`

const OnlineStatus = ({ isOnline, signalQuality }: { isOnline: boolean, signalQuality: number }) => {
    const status = convertSignalStatus(signalQuality, isOnline)

    return (
        <Tooltip title={status} placement="bottom-start">
            <SignalStatus status={status} isOnline={isOnline}>
                <div />
                <div />
                <div />
            </SignalStatus>
        </Tooltip>
    )
}

export default OnlineStatus

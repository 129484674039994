import { TextField as MuiTextField } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import { TextFieldProps } from './types'

const TextField = ({ name, label, sx, fullWidth, type }: TextFieldProps) => {
    const [field, meta] = useField({ name })
    
    return (
        <MuiTextField
            fullWidth={fullWidth}
            label={label}
            {...field}
            error={meta.touched && !!meta.error}
            sx={sx}
            type={type}
        />
    )
}

export default TextField

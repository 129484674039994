import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Box,
} from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
    title: string
    body: string | ReactNode
    open: boolean
    onClose: () => void
    onConfirm: () => void
    disableSubmit?: boolean
    isSubmitting?: boolean
}

const ConfirmModal = ({ title, body, open, onClose, onConfirm, disableSubmit, isSubmitting }: Props) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                {isSubmitting ? (
                    <Box display="flex" flexDirection="column" width={400} gap={4} alignItems="center">
                        <p>Submitting... </p>
                        <CircularProgress />
                    </Box>
                ) : (
                    body
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isSubmitting}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={onConfirm}
                    autoFocus
                    disabled={disableSubmit || isSubmitting}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal

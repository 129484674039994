import { Chip } from '@mui/material'
import React from 'react'
import { ColorVariants, Device, DeviceStatus as DeviceStatusType } from '../type'
import { getStatusFromSignalQuality } from '../utils'

export const statusMapper: Record<DeviceStatusType, ColorVariants> = {
    jammed: 'error',
    low: 'warning',
    online: 'success',
}

export const statusLabelMapper: Record<DeviceStatusType, string> = {
    jammed: 'Downlink',
    low: 'Low',
    online: 'Uplink',
}

const DeviceStatus = ({ device }: { device: Device }) => {
    const status = getStatusFromSignalQuality(device)

    return (
        <Chip
            label={statusLabelMapper[status]}
            color={statusMapper[status]}
            size="small"
            sx={{ borderRadius: '8px', fontSize: 12 }}
        />
    )
}

export default DeviceStatus

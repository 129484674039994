import { Box, Switch, Typography } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import { CommonFieldProps } from './types'

const SwitchButton = ({label, name, sx}: CommonFieldProps) => {
    const [field, meta, helper] = useField({ name })

    return (
        <Box display="flex" alignItems="center" sx={sx}>
            <Switch
                {...field}
                checked={field.value}
                onChange={(e, checked) => helper.setValue(checked)}
            />
            <Typography>{label}</Typography>
        </Box>
    )
}

export default SwitchButton

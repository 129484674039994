import React, { Dispatch, ReactNode } from 'react'
import { Action, AuthState } from './types'

const initialState: AuthState = {
    isAuthenticated: false,
    organization: null,
    user: null,
}

const reducer = (state: AuthState, action: Action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
            }
        case 'CURRENT_USER':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            }
        case 'LOGOUT':
            localStorage.clear()
            return initialState
        case 'SET_ORGANIZATION':
            return {
                ...state,
                organization: action.payload.organization,
            }
        default:
            return state
    }
}

export const AuthContext = React.createContext<{ state: AuthState; dispatch: Dispatch<Action> }>({
    state: initialState,
    dispatch: () => undefined,
})

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
        <AuthContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

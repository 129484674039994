import { Dialog, DialogTitle, Button, DialogActions, DialogContent, IconButton, Typography, ListItem, Chip, TextField as MuiTextField } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import api from '../../api'
import CustomIcon from '../../components/icons/CustomIcon'
import { UIContext } from '../../layout/UIContext'
import { Device } from '../../type'
import { convertErrorToText, validateEmail } from '../../utils'
import SwitchButton from '../forms/SwitchButton'
import TextField from '../forms/TextField'

const CreateUpdateDeviceModal = ({
    deviceData,
    refetchData,
    organizationId,
}: {
    deviceData?: Partial<Device>
    organizationId?: number
    refetchData: () => void
}) => {
    const [open, setOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [alertEmail, setAlertEmail] = useState('')
    const [subcribedEmails, setSubscribedEmails] = useState(deviceData?.subscribedEmails || [])
    const { dispatch } = useContext(UIContext)

    useEffect(() => {
        setSubscribedEmails(deviceData?.subscribedEmails || [])
    }, [deviceData?.subscribedEmails])

    const isUpdating = !!deviceData
    const initialValues = isUpdating
        ? {
              ...deviceData,
          }
        : {}

        const addAlertEmail = async () => {
            if (!alertEmail) return
    
            if (!validateEmail(alertEmail)) {
                dispatch({
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        message: 'Invalid email',
                        type: 'error',
                        duration: 3000,
                    },
                })
                return
            }
            try {
                dispatch({
                    type: 'SHOW_LOADING'
                })
                await api.addDeviceAlertEmail(deviceData?.id || -1, alertEmail)
                setAlertEmail('')
                setSubscribedEmails([alertEmail, ...subcribedEmails])
                dispatch({
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        message: 'Added alert email',
                        type: 'success',
                        duration: 3000,
                    },
                })
            } catch (error) {
                dispatch({
                    type: 'SHOW_NOTIFICATION',
                    payload: {
                        message: convertErrorToText(error),
                        type: 'error'
                    }
                })
            } finally {
                dispatch({
                    type: 'HIDE_LOADING'
                })
            }
        }
    
        const handleDelete = async (email: string) => {
            dispatch({
                type: 'SHOW_LOADING'
            })
            await api.removeDeviceAlertEmail(deviceData?.id || -1, email)
            setSubscribedEmails(subcribedEmails?.filter(e => e !== email))
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Removed alert email',
                    type: 'success',
                    duration: 3000,
                },
            })
            dispatch({
                type: 'HIDE_LOADING'
            })
        }

    const handleClose = () => setOpen(false)

    const handleSubmit = async (values: any, { resetForm }: any) => {
        try {
            setIsSubmitting(true)
            if (isUpdating) {
                await api.updateDevice(deviceData?.id || -1, {
                    ...values,
                    lat: parseFloat(values.lat) || 0,
                    long: parseFloat(values.long) || 0,
                })
            } else {
                if (!values.simId) {
                    dispatch({
                        type: 'SHOW_NOTIFICATION',
                        payload: {
                            message: 'The IMEI is required!',
                            type: 'error',
                        },
                    })
                    return
                }
                await api.createDevice({
                    ...values,
                    simId: values.simId,
                    lat: parseFloat(values.lat) || 0,
                    long: parseFloat(values.long) || 0,
                    note: values.note,
                    organizations: [organizationId],
                })
                resetForm({ values: {} })
            }
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: isUpdating ? 'Update device successfully!' : 'Create device successfully!',
                    type: 'success',
                },
            })
            handleClose()
        } catch (err: any) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: err?.message || 'Something went wrong!',
                    type: 'error',
                },
            })
        } finally {
            setIsSubmitting(false)
            refetchData()
        }
    }

    return (
        <>
            {isUpdating ? (
                <IconButton size="small" onClick={() => setOpen(true)} sx={{ mr: 1 }}>
                    <CustomIcon icon="edit" color="primary" sx={{ fontSize: 16 }} />
                </IconButton>
            ) : (
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<CustomIcon icon="plus" sx={{ color: 'white', fontSize: '16px' }} />}
                    sx={{ fontSize: '10px' }}
                    onClick={() => setOpen(true)}
                >
                    Add new device
                </Button>
            )}

            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    <Form>
                        <DialogTitle>{isUpdating ? 'Update device' : 'Create device'}</DialogTitle>
                        <DialogContent style={{ paddingTop: 10 }}>
                            <Box
                                sx={{
                                    '& .MuiTextField-root': { my: 1 },
                                }}
                            >
                                {!isUpdating && (
                                    <TextField label="IMEI" fullWidth type="number" name="simId" required />
                                )}
                                <Box display="flex" gap="32px">
                                    <TextField
                                        label="Latitude"
                                        sx={{ width: '50%' }}
                                        type="number"
                                        name="lat"
                                    />
                                    <TextField
                                        label="Longtitude"
                                        sx={{ width: '50%' }}
                                        type="number"
                                        name="long"
                                    />
                                </Box>
                                <Box display="flex" gap="32px">
                                    <TextField label="Note" sx={{ width: '50%' }} name="note" />
                                    <TextField label="ICCID" sx={{ width: '50%' }} name="iccid" />
                                </Box>
                                <Box display="flex" gap="32px">
                                    <TextField label="IMSI" sx={{ width: '50%' }} name="imsi" />
                                    <TextField label="MSISDN" sx={{ width: '50%' }} name="msisdn" />
                                </Box>
                                <Box display="flex" gap="32px">
                                    <TextField
                                        label="Device issue to"
                                        sx={{ width: '50%' }}
                                        name="deviceIssueTo"
                                    />
                                    <TextField
                                        label="Deployed At"
                                        sx={{ width: '50%' }}
                                        name="deployedAt"
                                    />
                                </Box>
                                <Box display="flex" gap="32px">
                                    <SwitchButton name="installedBuzzer" label="Buzzer installed" sx={{ width: '50%' }}/>
                                    <SwitchButton name="connectedToPower" label="Powered on 220V" sx={{ width: '50%' }}/>
                                </Box>
                                {isUpdating && (
                                    <Box sx={{ margin: 1, mb: 4, pt: 2, borderTop: '1px solid rgba(0,0,0,0.2)' }}>
                                        <Typography
                                            variant="overline"
                                            gutterBottom
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            Alert emails
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <MuiTextField
                                                label="Email"
                                                variant="outlined"
                                                size="small"
                                                value={alertEmail}
                                                onChange={(e) => setAlertEmail(e.target.value)}
                                            />
                                            <Button
                                                color="primary"
                                                onClick={addAlertEmail}
                                                disabled={!alertEmail}
                                                variant="contained"
                                                sx={{ ml: 2 }}
                                            >Add</Button>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                listStyle: 'none',
                                                mt: 2,
                                            }}
                                        >
                                            {subcribedEmails?.map((email) => (
                                                <ListItem key={email} sx={{ p: 0, width: 'auto', mr: 1 }}>
                                                    <Chip label={email} onDelete={() => handleDelete(email)} />
                                                </ListItem>
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined" color="warning">
                                Cancel
                            </Button>
                            <Button variant="contained" disabled={isSubmitting} type="submit">
                                {isUpdating ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        </>
    )
}

export default CreateUpdateDeviceModal

import { add, endOfDay, startOfDay, sub } from 'date-fns'
import {
    DEVICE_CONNECTED_CUTOFF,
    DEVICE_DETAIL_INTERVAL,
    JAMMED_SIGNAL_VALUE,
    LOW_SIGNAL_VALUE,
} from './constants'
import { ChartPoint, Device, DeviceStatus, Message } from './type'

export const validateEmail = (email: string) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )
}

export const convertErrorToText = (error: any) => {
    if (error.response) {
        // Request made and server responded
        const message = error.response.data?.detail || error.response.data?.message || error.response.data
        return message
    }
    if (error.request) {
        // The request was made but no response was received
        return 'Something went wrong'
    }
    // Something happened in setting up the request that triggered an Error
    return `${error.message}`
}

export const convertDateToNumber = (date: Date | string) => {
    return new Date(date).getTime()
}

export const convertMessageToChartData = (messages: Message[]) =>
    messages.map((e) => ({
        x: convertDateToNumber(e.created),
        y: e.signalQuality,
    }))

/**
 * When a device is off, there is no message sent to server
 * and we check if after DEVICE_CONNECTED_CUTOFF (8s), if no message is received, we assume that device is off.
 * We always fetch messages in only 1 day to show in chart.
 * (The chart has the date filter to select date to view the history)
 * So we will check if the duration between the startOfDay and the first message is greater than 8s or not
 * If yes, that means that device is off from startOfDay to when the first message received.
 * The same behavior for endOfDay vs the last message case.
 * This function is used for the history view, with the real-time graph, we will use the isOnline field of device.
 */
export const handeOffSignalData = (data: ChartPoint[], isRealtime?: boolean) => {
    if (!data || !data.length) {
        return []
    }
    const startPoint = convertDateToNumber(startOfDay(new Date(data[0].x)))
    const endPoint = isRealtime
        ? convertDateToNumber(new Date())
        : convertDateToNumber(endOfDay(new Date(data[data.length - 1].x)))
    const results: ChartPoint[] = []
    if (data[0].x - startPoint > DEVICE_CONNECTED_CUTOFF) {
        results.push({
            x: startPoint,
            y: 0,
        })
        results.push({
            x: data[0].x - DEVICE_DETAIL_INTERVAL,
            y: 0,
        })
    }
    for (let i = 0; i < data.length - 1; i++) {
        results.push(data[i])
        if (data[i + 1].x - data[i].x > DEVICE_CONNECTED_CUTOFF) {
            results.push({
                x: data[i].x + DEVICE_DETAIL_INTERVAL,
                y: 0,
            })
            results.push({
                x: data[i + 1].x - DEVICE_DETAIL_INTERVAL,
                y: 0,
            })
        }
    }
    results.push(data[data.length - 1])
    if (endPoint - data[data.length - 1].x > DEVICE_CONNECTED_CUTOFF) {
        results.push({
            x: data[data.length - 1].x + DEVICE_DETAIL_INTERVAL,
            y: 0,
        })
        results.push({
            x: endPoint,
            y: 0,
        })
    }
    return results
}

export const fakeChartData = (numOfPoints: number) => {
    const data = []
    const start = sub(new Date(), { minutes: 10 })
    for (let i = 0; i < numOfPoints; i++) {
        const point = {
            x: add(start, { seconds: i * 5 }).getTime(),
            y: i > 20 && i < 40 ? 0 : 20 + Math.floor(Math.random() * 4),
        }
        data.push(point)
    }
    return data
}

export const createPointFromDeviceInfo = (device: Device | null) => {
    if (!device?.isOnline) {
        return {
            x: convertDateToNumber(new Date()),
            y: 0,
        }
    }

    return {
        x: device.lastMessage,
        y: device.lastSignalQuality,
    }
}

export const is_jammed = (device: Device) =>
    device.lastSignalQuality <= JAMMED_SIGNAL_VALUE || !device.isOnline

export const is_low_signal_quality = (device: Device) =>
    !is_jammed(device) && device.lastSignalQuality <= LOW_SIGNAL_VALUE

export const getStatusFromSignalQuality = (device: Device): DeviceStatus => {
    if (is_jammed(device)) {
        return 'jammed'
    }

    if (is_low_signal_quality(device)) {
        return 'low'
    }

    return 'online'
}

export const convertDeviceDataForForm = (device: Device) => ({
    id: device.id,
    lat: `${device.lat}`,
    long: `${device.long}`,
    note: `${device.note}`,
    iccid: device.iccid,
    imsi: device.imsi,
    msisdn: device.msisdn,
    deviceIssueTo: device.deviceIssueTo,
    deployedAt: device.deployedAt,
    installedBuzzer: device.installedBuzzer,
    connectedToPower: device.connectedToPower,
    subscribedEmails: device.subscribedEmails,
})

export const getBatteryColor = (battery: number) => {
    if (battery < 20) {
        return 'error.light'
    }
    if (battery < 50) {
        return 'warning.light'
    }
    return 'success.main'
}

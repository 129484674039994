import React, { Dispatch, ReactNode } from 'react'
import { UIAction, UIState } from './types'

const initialState: UIState = {
    notification: {
        show: false
    },
    showLoading: false
}

const reducer = (state: UIState, action: UIAction) => {
    switch (action.type) {
        case 'HIDE_NOTIFICATION':
            return {
                ...state,
                notification: {
                    ...state.notification,
                    show: false,
                }
            }
        case 'SHOW_NOTIFICATION':
            return {
                ...state,
                notification: {
                    show: true,
                    ...action.payload
                }
            }
        case 'SHOW_LOADING':
            return {
                ...state,
                showLoading: true,
            }
        case 'HIDE_LOADING':
            return {
                ...state,
                showLoading: false,
            }
        default:
            return state
    }
}

export const UIContext = React.createContext<{ state: UIState; dispatch: Dispatch<UIAction> }>({
    state: initialState,
    dispatch: () => undefined,
})

const UIProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
        <UIContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </UIContext.Provider>
    )
}

export default UIProvider

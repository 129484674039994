import { useCallback, useEffect, useState } from 'react'
import { RequestStatus } from './type'

export const useAsync = <T, E = string>(asyncFunction: () => Promise<T>, immediate = true, preventFetching?: boolean) => {
    const [status, setStatus] = useState<RequestStatus>('idle')
    const [value, setValue] = useState<T | null>(null)
    const [error, setError] = useState<E | null>(null)
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => {
        if (preventFetching) {
            return null as unknown as Promise<void>
        }

        setStatus('pending')

        return asyncFunction()
            .then((response: any) => {
                setValue(response)
                setError(null)
                setStatus('success')
            })
            .catch((error: any) => {
                setValue(null)
                setError(error)
                setStatus('error')
            })
    }, [asyncFunction, preventFetching])
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute()
        }
    }, [execute, immediate])
    return { execute, status, value, error }
}

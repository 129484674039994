import { createRef, useLayoutEffect, useState } from 'react'
import styled from '@emotion/styled'
import auth from '../auth/auth'
import env from '../constants/env'

const S = {
    LoginWrapper: styled.div`
        z-index: 99998;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        background-color: rgba(0, 0, 0, 0.34);
    `,
    CenterForm: styled.div`
        z-index: 99999;
        margin: auto;
    `,
    ForgotPassword: styled.div`
        width: 100%;
        text-align: right;
        color: white;
        margin-top: 20px;
        cursor: pointer;
    `
}

const LoginModal = ({ onLogin = () => {} }: { onLogin?: () => void }) => {
    const loginRef = createRef()
    const resetPasswordRef = createRef()
    const [isResettingPassword, setIsResettingPassword] = useState(false)

    const close = () => {}

    const login = (event: any) => {
        const jwt = event.detail
        auth.storeTokens(jwt)
        onLogin()
    }

    useLayoutEffect(() => {
        // @ts-ignore
        loginRef?.current?.addEventListener?.('callback', login)
    })

    return (
        <S.LoginWrapper onClick={close}>
            <S.CenterForm onClick={(e: any) => e?.stopPropagation?.()}>
                {isResettingPassword ? (
                    // @ts-ignore
                    <digitpol-auth-widget ref={resetPasswordRef} resetPasswordLink={`${env.appUrl}/reset-password`} />
                ) : (
                    // @ts-ignore
                    <digitpol-auth-widget ref={loginRef} disableSignUp="true" />
                )}
                <S.ForgotPassword onClick={() => setIsResettingPassword(!isResettingPassword)}>
                    {!isResettingPassword ? 'Forgot your password?' : 'Back to login'}
                </S.ForgotPassword>
            </S.CenterForm>
        </S.LoginWrapper>
    )
}

export default LoginModal

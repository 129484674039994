import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface Props {
    label: string
    options: {value: any, label: string}[],
    value: any
    onChange: (value: any) => void
}

export default function SelectInput({label, options, value, onChange}: Props) {
  return (
    <FormControl sx={{ minWidth: 240 }} size="small">
      <InputLabel id="select-input">{label}</InputLabel>
      <Select
        labelId="select-input"
        id="select-input"
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((item) => (
            <MenuItem value={item.value}>
                {item.label}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React, { useContext } from 'react'
import { UIContext } from '../layout/UIContext'

export type NotificationType = 'success' | 'warning' | 'error' | 'info'

export interface NotificationProps {
    open?: boolean
    message?: string
    onClose?: () => void
    duration?: number
    type?: NotificationType
}

const Notification = () => {
    const {
        state: { notification },
        dispatch,
    } = useContext(UIContext)
    const handleClose = () => {
        dispatch({ type: 'HIDE_NOTIFICATION' })
    }

    return (
        <Snackbar
            open={notification.show}
            autoHideDuration={notification.duration || 5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={notification.type || 'info'} variant="filled">
                {notification.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification

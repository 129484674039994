import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import api from '../api'
import { AuthContext } from './AuthContext'
import { getAccessToken } from './jwt'
import LoginModal from './LoginModal'

const noAuthPaths = ['/create-account', '/reset-password']

const LoginGuard = ({ children }: { children: any }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { state, dispatch } = useContext(AuthContext)

    useEffect(() => {
        let active = true
        if (noAuthPaths.includes(location.pathname)) {
            return
        }

        ;(async () => {
            try {
                const user = await api.getCurrentUser()
                if (!active) return
                if (!user) {
                    dispatch({ type: 'LOGOUT' })
                    navigate('/')
                    return
                }
                dispatch({
                    type: 'CURRENT_USER',
                    payload: {
                        user,
                    },
                })
                if (user.isSuperuser) {
                    location.pathname === '/' && navigate('/organizations')
                }
            } catch (err) {
                dispatch({ type: 'LOGOUT' })
                navigate('/')
            }
        })()

        return () => {
            active = false
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isAuthenticated])

    useEffect(() => {
        let active = true
        if (noAuthPaths.includes(location.pathname)) {
            return
        }

        ;(async () => {
            if (state.isAuthenticated && state.user && !state.user?.isSuperuser) {
                try {
                    const myOrganization = await api.getMyOrganization()
                    if (!active) return
                    if (myOrganization) {
                        dispatch({ type: 'SET_ORGANIZATION', payload: { organization: myOrganization } })
                        location.pathname === '/' && navigate('/devices')
                    }
                } catch (err) {
                    navigate('/no-organization')
                }
            }
        })()

        return () => {
            active = false
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isAuthenticated, state.user?.isSuperuser])

    if (noAuthPaths.includes(location.pathname)) {
        return children
    }

    if (!state.isAuthenticated && !getAccessToken()) {
        return (
            <>
                <br />
                <br />
                <br />
                <br />
                <LoginModal onLogin={() => dispatch({ type: 'LOGIN' })} />
            </>
        )
    }

    return children
}

export default LoginGuard

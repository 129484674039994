import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useContext } from 'react'
import { UIContext } from '../layout/UIContext'

const FullpageLoading = ({open}: {open?: boolean}) => {
    const {state} = useContext(UIContext)
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex:  1301 }}
            open={open || state.showLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default FullpageLoading

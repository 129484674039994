import {
    Alert,
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material'
import { format, formatDistanceToNow } from 'date-fns'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../api'
import FullpageLoading from '../components/FullpageLoading'
import CustomIcon from '../components/icons/CustomIcon'
import OnlineStatus from '../components/OnlineStatus'
import UpdateCountDown from '../components/UpdateCountDown'
import { DATETIME_FORMAT } from '../constants'
import CreateUpdateDeviceModal from '../features/device/CreateUpdateDeviceModal'
import { useAsync } from '../hooks'
import useDebounceValue from '../hooks/useDebounceValue'
import { UIContext } from '../layout/UIContext'
import { Device } from '../type'
import { convertDeviceDataForForm, getBatteryColor } from '../utils'

const Devices = () => {
    const [search, setSearch] = useState('')
    const searchParam = useDebounceValue(search)

    const { value: stats, execute: fetchStats } = useAsync(api.getDeviceStats)
    
    const getDevices = useCallback(() => api.getDevices({search: searchParam}), [searchParam])
    const { value: devicesResponse, execute: fetchDevices, status } = useAsync(getDevices)
    const devices = devicesResponse?.results || []
    const { dispatch } = useContext(UIContext)
    

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchStats()
            fetchDevices()
        }, 8000)

        return () => clearInterval(intervalId)
    }, [fetchDevices, fetchStats])

    const updateEnableAlerts = async (device: Device) => {
        dispatch({ type: 'SHOW_LOADING' })
        await api.updateDevice(device.id, {
            enableAlerts: !device.enableAlerts,
        })
        await fetchDevices()
        dispatch({ type: 'HIDE_LOADING' })
    }

    if (!devicesResponse?.results && status === 'pending') {
        return <FullpageLoading open={true} />
    }

    return (
        <>
            <Container sx={{ pt: 4 }}>
                {stats && (
                    <Alert severity="info">
                        <strong>
                            {stats.onlineCount}/{stats.totalCount}
                        </strong>{' '}
                        devices are online
                    </Alert>
                )}
                <UpdateCountDown />
                
                <TextField
                    size="small"
                    variant="outlined"
                    label="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{mt: 1, width: 400}}
                    helperText="Search on IMEI, ICCID, IMSI, MSISDN, deployed at, issue to"
                />
                <TableContainer component={Paper} sx={{ mt: 2 }} elevation={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>IMEI</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Last online</TableCell>
                                <TableCell>Network</TableCell>
                                <TableCell>Power (220v)</TableCell>
                                <TableCell>Battery</TableCell>
                                <TableCell>Note</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.map((device) => (
                                <TableRow
                                    key={device.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ fontSize: 12, maxWidth: 150 }}
                                        className="text-cut"
                                    >
                                        {device.simId}
                                    </TableCell>
                                    <TableCell>
                                        <OnlineStatus
                                            isOnline={device.isOnline}
                                            signalQuality={device.lastSignalQuality}
                                        />
                                    </TableCell>
                                    <TableCell style={{ cursor: 'default' }}>
                                        {device.isOnline ? (
                                            <div style={{ fontSize: '12px' }}>Now</div>
                                        ) : (
                                            device.lastMessage && (
                                                <Tooltip
                                                    title={format(
                                                        new Date(device.lastMessage),
                                                        DATETIME_FORMAT
                                                    )}
                                                    placement="bottom-start"
                                                >
                                                    <div style={{ fontSize: '12px' }}>
                                                        {formatDistanceToNow(new Date(device.lastMessage), {
                                                            includeSeconds: true,
                                                            addSuffix: true,
                                                        })}
                                                    </div>
                                                </Tooltip>
                                            )
                                        )}
                                    </TableCell>

                                    <TableCell sx={{ fontSize: 12, maxWidth: 100 }} className="text-cut">
                                        <Tooltip title={device.connectedTower || ''} placement="bottom-start">
                                            <span>{device.connectedTower}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ fontSize: 12, maxWidth: 50 }} className="text-cut">
                                        <span>{device.connectedToPower ? 'Yes' : 'No'}</span>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontSize: 12, fontWeight: 500, maxWidth: 50, color: getBatteryColor(device.batteryLevel || 0) }}
                                        className="text-cut"
                                    >
                                        <span>{device.batteryLevel}%</span>
                                    </TableCell>
                                    <TableCell sx={{ fontSize: 12, maxWidth: 100 }} className="text-cut">
                                        <Tooltip title={device.note || ''} placement="bottom-start">
                                            <span>{device.note}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 40 }}>
                                        <div className="flex-center">
                                            <Tooltip
                                                title={
                                                    device.enableAlerts
                                                        ? 'Click to deactive alert'
                                                        : 'Click to active alert'
                                                }
                                            >
                                                <IconButton
                                                    size="small"
                                                    sx={{ mr: 1 }}
                                                    onClick={() => updateEnableAlerts(device)}
                                                >
                                                    <CustomIcon
                                                        icon={device.enableAlerts ? 'bellCheck' : 'bellOff'}
                                                        color={device.enableAlerts ? 'success' : 'error'}
                                                        sx={{ fontSize: 16 }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Link to={`/devices/${device.id}`}>
                                                <IconButton size="small">
                                                    <CustomIcon
                                                        icon="view"
                                                        color="primary"
                                                        sx={{ fontSize: 16 }}
                                                    />
                                                </IconButton>
                                            </Link>
                                            <CreateUpdateDeviceModal
                                                refetchData={fetchDevices}
                                                deviceData={convertDeviceDataForForm(device)}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default Devices

import {Box, Button, Container, Grid} from '@mui/material'
import React from 'react'
import {useNavigate, useParams} from 'react-router'
import CustomIcon from '../components/icons/CustomIcon'
import {DeviceStatusChart} from '../features/device/DeviceStatusChart'
import MessageTable from '../features/device/MessageTable'
import StatusChangesTable from '../features/device/StatusChangesTable'
import Connections from '../features/device/Connections'
import RequestLogsDialog from '../features/device/RequestLogs'
import TowerTable from "../features/device/TowerTable";
import DeleteSignalsModal from '../features/device/DeleteSignalsModal'

const Messages = () => {
    const navigate = useNavigate()
    const {deviceId} = useParams()

    return (
        <Container sx={{mt: 2}} maxWidth="xl">
            <Box display="flex" justifyContent="space-between">
                <Button onClick={() => navigate('/devices')} variant="outlined">
                    <CustomIcon icon="chevUp" sx={{transform: 'rotate(-90deg)'}} color="primary"/>
                    Back to devices
                </Button>
                
                <Box display="flex" gap={2}>
                    <DeleteSignalsModal deviceId={deviceId} />
                    <RequestLogsDialog deviceId={deviceId}/>
                </Box>
            </Box>

            <Grid container spacing={2} mb={4}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        paddingBottom: '24px',
                    }}
                    order={{xs: 2, sm: 2, md: 1}}
                >
                    <MessageTable deviceId={deviceId}/>
                </Grid>
                <Grid item xs={12} sm={12} md={8} order={{xs: 1, sm: 1, md: 2}}>
                    <DeviceStatusChart />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} order={3}>
                    <Connections deviceId={deviceId}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={7} order={4}>
                    <StatusChangesTable deviceId={deviceId}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} order={5}>
                    <TowerTable deviceId={deviceId}/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Messages

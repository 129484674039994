import { Chip, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarContainer, gridClasses } from '@mui/x-data-grid'
import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import api from '../../api'
import {statusLabelMapper, statusMapper} from '../../components/DeviceStatus'
import ExportDialog from '../../components/ExportDialog'
import { DATETIME_FORMAT, DEVICE_DETAIL_INTERVAL } from '../../constants'
import { useAsync } from '../../hooks'
import {DeviceStatus as DeviceStatusType} from "../../type";

const CustomToolbar = ({ id }: { id: string }) => {
    const formatField = (key: string, value: string): string => (
        statusLabelMapper[value as DeviceStatusType] || value
    )
    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <ExportDialog
                id={id}
                fetchData={api.getDeviceStatusChanges}
                filename="status-change-logs"
                fields={['created', 'previousStatus', "status"]}
                headers={['Date', 'Previous Status', "Current Status"]}
                formatField={formatField}
            />
        </GridToolbarContainer>
    )
}

const StatusChangesTable = ({ deviceId }: { deviceId?: string }) => {
    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(10)

    const fetchDeviceStatusChanges = useCallback(
        () => api.getDeviceStatusChanges(deviceId, { limit: pageSize, offset: page * pageSize }),
        [deviceId, page, pageSize]
    )
    const { value: deviceStatusChangesRespone, status, execute } = useAsync(fetchDeviceStatusChanges)
    const towers = deviceStatusChangesRespone?.results || []

    useEffect(() => {
        const intervalId = setInterval(() => {
            execute()
        }, DEVICE_DETAIL_INTERVAL)

        return () => clearInterval(intervalId)
    }, [execute])

    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        {
            field: 'created',
            headerName: 'Date',
            flex: 1,
            sortable: false,
            valueFormatter: (params) => format(new Date(params.value as string), DATETIME_FORMAT),
        },
        {
            field: 'previousStatus',
            headerName: 'Previous Status',
            flex: 1,
            sortable: false,
            renderCell: (params: any) => (
                <Chip
                    label={statusLabelMapper[params.value as DeviceStatusType]}
                    // @ts-ignore
                    color={statusMapper[params.value]}
                    size="small"
                    sx={{ fontSize: 11, borderRadius: '8px' }}
                />
            ),
        },
        {
            field: 'status',
            headerName: 'Current Status',
            flex: 1,
            sortable: false,
            renderCell: (params: any) => (
                <Chip
                    label={statusLabelMapper[params.value as DeviceStatusType]}
                    // @ts-ignore
                    color={statusMapper[params.value]}
                    size="small"
                    sx={{ fontSize: 11, borderRadius: '8px' }}
                />
            ),
        },
    ]

    return (
        <>
            <Typography variant="h6" p={1}>
                Device Data Traffic Status
            </Typography>
            <div style={{ height: 490 }}>
                <DataGrid
                    rowHeight={34}
                    disableColumnMenu
                    columns={columns}
                    rows={towers}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={deviceStatusChangesRespone?.count}
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    loading={status === 'pending'}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: { id: deviceId },
                    }}
                />
            </div>
        </>
    )
}

export default StatusChangesTable

/* eslint-disable import/no-anonymous-default-export */
import _axios from 'axios'
import { User } from './auth/types'
import env from './constants/env'
import {
    CreateDeviceInput,
    DeviceStats,
    ListResponse,
    Device,
    Tower,
    Message,
    Organization,
    DeviceStatusChange,
    Connection,
    RequestLog,
} from './type'

export const axios = _axios.create({
    baseURL: env.apiUrl,
})

export const plainAxios = _axios.create({
    baseURL: env.authApiUrl,
})

export const authAxios = _axios.create({
    baseURL: env.authApiUrl,
})

/* eslint-disable import/no-anonymous-default-export */
export default {
    createDevice: async (data: CreateDeviceInput): Promise<void> => {
        await axios.post('/devices/devices/', data)
    },
    updateDevice: async (deviceId: number, data: Partial<CreateDeviceInput>): Promise<void> => {
        await axios.patch(`/devices/devices/${deviceId}/`, data)
    },
    getDevices: async (queryParams?: any): Promise<ListResponse<Device>> => {
        const params = new URLSearchParams(queryParams).toString()
        const response = await axios.get(`/devices/devices/?${params}`)
        return response.data
    },
    getDeviceDetail: async (id?: string): Promise<Device> => {
        const response = await axios.get(`/devices/devices/${id}`)
        return response.data
    },
    getDeviceStats: async (): Promise<DeviceStats> => {
        const response = await axios.get('/devices/devices/stats/')
        return response.data
    },
    getDeviceTowers: async (id?: string, queryParams?: any): Promise<ListResponse<Tower>> => {
        const params = new URLSearchParams(queryParams).toString()
        const response = await axios.get(`/devices/towers/table/${id}/?${params}`)
        return response.data
    },
    getDeviceMessages: async (id?: string, queryParams?: any): Promise<ListResponse<Message>> => {
        const params = new URLSearchParams(queryParams).toString()
        const response = await axios.get(`/devices/message/table/${id}/?${params}`)
        return response.data
    },
    getDeviceConnections: async (id?: string, queryParams?: any): Promise<ListResponse<Connection>> => {
        const params = new URLSearchParams(queryParams).toString()
        const response = await axios.get(`/devices/connections/${id}/?${params}`)
        return response.data
    },
    getCurrentUser: async (): Promise<User> => {
        const response = await authAxios.get('/users/current/')
        return response.data
    },
    getMyOrganization: async () => {
        const response = await axios.get('/organizations/my_organization/')
        return response.data
    },
    getOrganizationList: async (): Promise<ListResponse<Organization>> => {
        const response = await axios.get('/organizations/')
        return response.data
    },
    getOrganization: async (id?: string): Promise<Organization> => {
        const response = await axios.get(`/organizations/${id}/`)
        return response.data
    },
    createOrganization: async (name: string) => {
        await axios.post('/organizations/', { name, members: [], devices: [] })
    },
    updateOrganization: async (id: string, data: {name?: string, alertEmail?: string}) => {
        await axios.patch(`/organizations/${id}/`, data)
    },
    deleteDevice: async (deviceId: number) => {
        await axios.delete(`/devices/devices/${deviceId}/`)
    },
    addMember: async (organizationId: number, email: string) => {
        await axios.post(`/organizations/${organizationId}/add_member/`, { email })
    },
    removeMember: async (organizationId: number, memberId: number) => {
        await axios.post(`/organizations/${organizationId}/remove_member/`, { id: memberId })
    },
    getDeviceStatusChanges: async (
        id?: string,
        queryParams?: any
    ): Promise<ListResponse<DeviceStatusChange>> => {
        const params = new URLSearchParams(queryParams).toString()
        const response = await axios.get(`/devices/status_changes/${id}/?${params}`)
        return response.data
    },
    getRequestLogs: async (
        queryParams?: any
    ): Promise<ListResponse<RequestLog>> => {
        const params = new URLSearchParams(queryParams).toString()
        const response = await axios.get(`/request_logs/?${params}`)
        return response.data
    },
    addOrganizationAlertEmail: async (organizationId: number, email: string) => {
        await axios.post(`/organizations/${organizationId}/add_alert_email/`, { email })
    },
    removeOrganizationAlertEmail: async (organizationId: number, email: string) => {
        await axios.post(`/organizations/${organizationId}/remove_alert_email/`, { email })
    },
    addDeviceAlertEmail: async (deviceId: number, email: string) => {
        await axios.post(`/devices/devices/${deviceId}/add_alert_email/`, { email })
    },
    removeDeviceAlertEmail: async (deviceId: number, email: string) => {
        await axios.post(`/devices/devices/${deviceId}/remove_alert_email/`, { email })
    },
    addExistingDeviceToOrganization: async (organizationId: number, deviceSimId: string) => {
        await axios.post(`/organizations/${organizationId}/add_existing_device/`, { device: deviceSimId })
    },
    removeDeviceFromOrganization: async (organizationId: number, deviceId: number) => {
        await axios.post(`/organizations/${organizationId}/remove_device/`, { device: deviceId })
    },
    removeDeviceSignals: async (deviceId: string) => {
        await axios.delete(`/devices/devices/${deviceId}/async_delete_signals`)
    },
}

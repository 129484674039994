/* eslint-disable react-hooks/rules-of-hooks */
import { clearAuthTokens, getAccessToken, setAuthTokens, useAuthTokenInterceptor } from './jwt'
import { authAxios, axios, plainAxios } from '../api'
import { Jwt } from './types'

const storeTokens = (jwt: Jwt) => {
    setAuthTokens({
        access: jwt.access,
        refresh: jwt.refresh,
    })
}

const requestRefresh = async (refresh: string): Promise<string> => {
    const resp = await plainAxios.post<Jwt>('/jwt/refresh/', { refresh })
    return resp.data.access
}

useAuthTokenInterceptor(axios, { requestRefresh })
useAuthTokenInterceptor(authAxios, { requestRefresh })

export default {
    getAccessToken,
    storeTokens,
    logout: clearAuthTokens,
}

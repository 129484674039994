import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import api from '../../api'
import ConfirmModal from '../../components/ConfirmModal'
import CustomIcon from '../../components/icons/CustomIcon'

interface Props {
    organizationId: number
    deviceId: number
    refetchData: () => Promise<void>
}

const DeleteDeviceModal = ({ organizationId, deviceId, refetchData }: Props) => {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleRemoveDeviceFromOrganization = async () => {
        await api.removeDeviceFromOrganization(organizationId, deviceId)
        refetchData()
        handleClose()
    }

    return (
        <>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <CustomIcon icon="trash" color="error" sx={{ fontSize: 16 }} />
            </IconButton>
            <ConfirmModal
                title="Delete device"
                body="Are you sure to remove this device from organization?"
                onClose={handleClose}
                open={open}
                onConfirm={handleRemoveDeviceFromOrganization}
            />
        </>
    )
}

export default DeleteDeviceModal

import {
    Button,
    Container,
    Divider,
    InputBase,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useContext, useState } from 'react'
import api from '../api'
import FullpageLoading from '../components/FullpageLoading'
import CustomIcon from '../components/icons/CustomIcon'
import OrganizationRow from '../features/organization/OrganizationRow'
import { useAsync } from '../hooks'
import { UIContext } from '../layout/UIContext'

const Organizations = () => {
    const { value, status, execute: fetchList } = useAsync(api.getOrganizationList)
    const [orgName, setOrgName] = useState('')
    const organizations = value?.results || []
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { dispatch } = useContext(UIContext)

    const handleCreateOrganization = async () => {
        try {
            setIsSubmitting(true)
            await api.createOrganization(orgName)
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Create organization successfully!',
                    type: 'success',
                },
            })
            setOrgName('')
            fetchList()
        } catch (err: any) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: err?.message || 'Something went wrong!',
                    type: 'error',
                },
            })
        } finally {
            setIsSubmitting(false)
        }
    }

    if (!value?.results && status === 'pending') {
        return <FullpageLoading open={true} />
    }

    return (
        <Container sx={{ pt: 4 }}>
            <div className="flex-space-between">
                <Typography variant="h5" color={grey[700]}>
                    Organizations
                </Typography>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}
                >
                    <InputBase
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Organization name..."
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Enter name then click here to create new organization">
                        <div>
                            <Button
                                color="primary"
                                startIcon={<CustomIcon icon="plus" color={orgName ? 'primary' : 'action'} />}
                                disabled={!orgName || isSubmitting}
                                onClick={handleCreateOrganization}
                            >
                                Create
                            </Button>
                        </div>
                    </Tooltip>
                </Paper>
            </div>

            <Paper elevation={3} sx={{ mt: 2 }}>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Created Date</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Members</TableCell>
                                <TableCell>Devices</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizations.map((organization) => (
                                <OrganizationRow organization={organization} key={organization.id} fetchList={fetchList} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    )
}

export default Organizations

import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton, Tooltip
} from '@mui/material'
import { endOfDay, startOfDay } from 'date-fns'
import { format } from 'date-fns/esm'
import React, { useContext, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import XLSX from 'xlsx'
import { DATETIME_FORMAT } from '../constants'
import { UIContext } from '../layout/UIContext'
import CustomIcon from './icons/CustomIcon'

export type FormatField = (key: string, value: string) => string

interface ExportDialogProps {
    id: string
    fetchData: (id: string, params: any) => Promise<any>
    filename: string
    formatField?: FormatField
    headers: string[]
    fields: string[]
}

const ExportDialog = (props: ExportDialogProps) => {
    const { id, fetchData, filename, headers, fields } = props
    const formatField = props.formatField || ((key, value) => value)
    const { dispatch } = useContext(UIContext)
    const [open, setOpen] = useState(false)
    const [dateFilter, setDateFilter] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

    async function exportData() {
        dispatch({
            type: 'SHOW_LOADING',
        })
        const params = {
            start_date: startOfDay(dateFilter[0].startDate || new Date()).toISOString(),
            end_date: endOfDay(dateFilter[0].endDate || new Date())?.toISOString(),
            limit: 100000, // Max 100000 rows per file
        }
        const response = await fetchData(id, params)
        if (response.count === 0) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: { message: "No data to export.", type: 'info' },
            })
            setOpen(false)
            dispatch({
                type: 'HIDE_LOADING',
            })
            return
        }

        // Export data to csv
        const convertedData = response.results.map((e: any) =>
            fields.map((field) => {
                if (field === 'created') {
                    return format(new Date(e[field]), DATETIME_FORMAT)
                }
                return formatField(field, e[field])
            })
        )
        const ws = XLSX.utils.aoa_to_sheet([headers, ...convertedData])
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, `${filename}.csv`)

        setOpen(false)
        dispatch({
            type: 'HIDE_LOADING',
        })
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div style={{ marginLeft: 'auto' }}>
            <IconButton sx={{ ml: 'auto', mr: 1 }} onClick={handleClickOpen}>
                <CustomIcon icon="export" color="primary" sx={{ fontSize: 18 }} />
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>Export Data</DialogTitle>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 3 }}>
                            Please select the date range to filter date for exporting the data.
                        </DialogContentText>

                        <DateRangePicker
                            onChange={(item: any) => setDateFilter([item.selection])}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateFilter}
                            direction="horizontal"
                            preventSnapRefocus={true}
                            calendarFocus="backwards"
                            maxDate={new Date()}
                        />
                    </DialogContent>
                </LocalizationProvider>
                <DialogActions>
                    <Button onClick={handleClose} color="warning">
                        Cancel
                    </Button>
                    <Tooltip title={!dateFilter[0].startDate ? 'Please select start date!' : ''} placement="top-end">
                        <div>
                            <Button
                                onClick={exportData}
                                variant="outlined"
                                sx={{ mx: 2 }}
                                disabled={!dateFilter[0].startDate}
                            >
                                <CustomIcon
                                    icon="export"
                                    color={!dateFilter[0].startDate ? 'disabled' : 'primary'}
                                    sx={{ fontSize: 18, mr: 1 }}
                                />
                                Export
                            </Button>
                        </div>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ExportDialog

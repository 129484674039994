import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    Container,
    Divider,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import api from '../api'
import EditableTextField from '../components/EditableTextField'
import CustomIcon from '../components/icons/CustomIcon'
import CreateUpdateDeviceModal from '../features/device/CreateUpdateDeviceModal'
import AddMemberModal from '../features/organization/AddMemberModal'
import DeleteDeviceModal from '../features/organization/DeleteDeviceModal'
import RemoveMemberModal from '../features/organization/RemoveMemberModal'
import { useAsync } from '../hooks'
import { UIContext } from '../layout/UIContext'
import { convertDeviceDataForForm, convertErrorToText, validateEmail } from '../utils'

const OrganizationDetail = () => {
    const { dispatch } = useContext(UIContext)
    const navigate = useNavigate()
    const { id } = useParams()
    const fetchOrganizationCb = useCallback(() => api.getOrganization(id), [id])
    const { value: organization, execute: fetchData } = useAsync(fetchOrganizationCb)
    const [name, setName] = useState('')
    const [alertEmail, setAlertEmail] = useState('')
    const [removeModal, setRemoveModal] = useState(false)
    const [removeId, setRemoveId] = useState(0)
    const [existingDeviceImei, setExistingDeviceImei] = useState('')

    useEffect(() => {
        if (organization?.name) {
            setName(organization.name)
        }
    }, [organization?.name])

    const changeOrganizationName = async () => {
        await api.updateOrganization(id || '', { name })
    }

    const addAlertEmail = async () => {
        if (!alertEmail) return

        if (!validateEmail(alertEmail)) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Invalid email',
                    type: 'error',
                    duration: 3000,
                },
            })
            return
        }
        try {
            dispatch({
                type: 'SHOW_LOADING'
            })
            await api.addOrganizationAlertEmail(organization?.id || -1, alertEmail)
            await fetchData()
            setAlertEmail('')
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Added alert email',
                    type: 'success',
                    duration: 3000,
                },
            })
        } catch (error) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: convertErrorToText(error),
                    type: 'error'
                }
            })
        } finally {
            dispatch({
                type: 'HIDE_LOADING'
            })
        }
    }

    const showRemoveModal = async (id: number) => {
        setRemoveModal(true)
        setRemoveId(id)
    }

    const handleDelete = async (email: string) => {
        dispatch({
            type: 'SHOW_LOADING'
        })
        await api.removeOrganizationAlertEmail(organization?.id || -1, email)
        await fetchData()
        dispatch({
            type: 'SHOW_NOTIFICATION',
            payload: {
                message: 'Removed alert email',
                type: 'success',
                duration: 3000,
            },
        })
        dispatch({
            type: 'HIDE_LOADING'
        })
    }

    const addExistingDeviceToOrganization = async () => {
        try {
            await api.addExistingDeviceToOrganization(organization?.id || -1, existingDeviceImei)
            setExistingDeviceImei('')
            await fetchData()
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: 'Added',
                    type: 'success',
                    duration: 3000,
                },
            })
        } catch(error) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: convertErrorToText(error),
                    type: 'error'
                }
            })
        }
    }

    return (
        organization && (
            <Container>
                <Button onClick={() => navigate('/organizations')} variant="outlined" sx={{ mt: 2 }}>
                    <CustomIcon icon="chevUp" sx={{ transform: 'rotate(-90deg)' }} color="primary" />
                    Back to organizations
                </Button>
                <Card sx={{ mt: 2, p: 2, overflow: 'auto' }}>
                    <Box sx={{ margin: 1, mb: 4 }}>
                        <Typography
                            variant="overline"
                            gutterBottom
                            component="h1"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Organization name
                        </Typography>
                        <EditableTextField
                            value={name}
                            onChange={(value) => setName(value)}
                            onSave={changeOrganizationName}
                        />
                    </Box>
                    <Box sx={{ margin: 1, mb: 4 }}>
                        <Typography
                            variant="overline"
                            gutterBottom
                            component="h1"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Alert emails
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <TextField
                                label="Email"
                                variant="outlined"
                                size="small"
                                value={alertEmail}
                                onChange={(e) => setAlertEmail(e.target.value)}
                            />
                            <Button
                                color="primary"
                                onClick={addAlertEmail}
                                disabled={!alertEmail}
                                variant="contained"
                                sx={{ ml: 2 }}
                            >Add</Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                mt: 2,
                            }}
                        >
                            {(organization.subscribedEmails || []).map((email) => (
                                <ListItem key={email} sx={{ p: 0, width: 'auto', mr: 1 }}>
                                    <Chip label={email} onDelete={() => handleDelete(email)} />
                                </ListItem>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ margin: 1, mb: 4 }}>
                        <Typography
                            variant="overline"
                            gutterBottom
                            component="span"
                            sx={{ fontWeight: 'bold', mr: 2 }}
                        >
                            Members
                        </Typography>
                        <AddMemberModal organizationId={organization.id} refetchData={fetchData} />
                        <Box pt={2} />
                        {!organization.members.length && <Alert severity="info">No members available</Alert>}
                        {organization.members.map((member) => (
                            <Chip
                                label={member.email}
                                variant="outlined"
                                onDelete={() => showRemoveModal(member.id)}
                                color="primary"
                                sx={{ mr: 1, mb: 1 }}
                            />
                        ))}
                        <RemoveMemberModal
                            open={removeModal}
                            handleClose={() => setRemoveModal(false)}
                            organizationId={organization.id}
                            memberId={removeId}
                            refetchData={fetchData}
                        />
                    </Box>
                    <Divider />
                    <Box sx={{ margin: 1, mt: 4 }}>
                        <Typography
                            variant="overline"
                            gutterBottom
                            component="span"
                            sx={{ fontWeight: 'bold', mr: 3 }}
                        >
                            Devices
                        </Typography>
                        <CreateUpdateDeviceModal refetchData={fetchData} organizationId={organization.id} />
                        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                            <TextField
                                label="IMEI"
                                variant="outlined"
                                size="small"
                                value={existingDeviceImei}
                                onChange={(e) => setExistingDeviceImei(e.target.value)}
                                type="number"
                            />
                            <Button
                                color="primary"
                                onClick={addExistingDeviceToOrganization}
                                disabled={!existingDeviceImei}
                                variant="contained"
                                sx={{ ml: 2 }}
                            >Add an existing device</Button>
                        </Box>
                        <Box pt={2} />
                        {!organization.devices.length ? (
                            <Alert severity="info">No devices available</Alert>
                        ) : (
                            <Table size="small">
                                <TableHead sx={{ fontSize: '14px' }}>
                                    <TableRow>
                                        <TableCell>IMEI</TableCell>
                                        <TableCell>Network</TableCell>
                                        <TableCell>Networks Available</TableCell>
                                        <TableCell>Note</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organization.devices.map((device) => (
                                        <TableRow
                                            key={device.simId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{ fontSize: 12, maxWidth: 150 }}
                                                className="text-cut"
                                            >
                                                {device.simId}
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontSize: 12, maxWidth: 100 }}
                                                className="text-cut"
                                            >
                                                <Tooltip
                                                    title={device.connectedTower || ''}
                                                    placement="bottom-start"
                                                >
                                                    <span>{device.connectedTower}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontSize: 12, maxWidth: 100 }}
                                                className="text-cut"
                                            >
                                                <Tooltip
                                                    title={device.availableTowers || ''}
                                                    placement="bottom-start"
                                                >
                                                    <span>{device.availableTowers}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontSize: 12, maxWidth: 100 }}
                                                className="text-cut"
                                            >
                                                <Tooltip title={device.note || ''} placement="bottom-start">
                                                    <span>{device.note}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                                                <CreateUpdateDeviceModal
                                                    refetchData={fetchData}
                                                    organizationId={organization.id}
                                                    deviceData={convertDeviceDataForForm(device)}
                                                />

                                                <DeleteDeviceModal
                                                    organizationId={organization.id}
                                                    deviceId={device.id}
                                                    refetchData={fetchData}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </Box>
                </Card>
            </Container>
        )
    )
}

export default OrganizationDetail

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Devices from './pages/Devices'
import LoginGuard from './auth/LoginGuard'
import './app.css'
import DeviceDetail from './pages/DeviceDetail'
import browserHistory from './browserHistory'
import CustomRouter from './CustomRouter'
import './auth/auth'
import AuthProvider from './auth/AuthContext'
import Organizations from './pages/Organizations'
import Layout from './layout/Layout'
import OrganizationDetail from './pages/OrganizationDetail'
import Notification from './components/Notification'
import FullpageLoading from './components/FullpageLoading'
import NoOrganization from './pages/NoOrganization'
import CreateAccount from './pages/CreateAccount';

const App = () => (
    <AuthProvider>
        <CustomRouter history={browserHistory}>
            <Layout>
                <LoginGuard>
                    <Routes>
                        <Route path="/create-account" element={<CreateAccount />} />
                        <Route path="/reset-password" element={<CreateAccount isResettingPassword />} />
                        <Route path="/devices" element={<Devices />} />
                        <Route path="/devices/:deviceId" element={<DeviceDetail />} />
                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/organizations/:id" element={<OrganizationDetail />} />
                        <Route path="/no-organization" element={<NoOrganization />} />
                    </Routes>
                </LoginGuard>
                <Notification />
                <FullpageLoading />
            </Layout>
        </CustomRouter>
    </AuthProvider>
)

export default App

import { Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import CustomIcon from '../../components/icons/CustomIcon'
import ConfirmModal from '../../components/ConfirmModal'
import api from '../../api'
import { convertErrorToText } from '../../utils'
import { UIContext } from '../../layout/UIContext'

const DeleteSignalsModal = ({deviceId}: {deviceId?: string}) => {
    const {dispatch} = useContext(UIContext)
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [loading, setLoading] = useState(false)
    const handleDeleteDeviceSignals = async () => {
        try {
            setLoading(true)
            deviceId && await api.removeDeviceSignals(deviceId)
            setTimeout(() => {
                handleClose()
                setLoading(false)
                window.location.reload()
            }, 5000)
            
        } catch (err) {
            dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {
                    message: convertErrorToText(err),
                    type: 'error'
                }
            })
        }
        
    }

    return (
        <>
            <Button variant="outlined" color="error" size="small" onClick={() => setOpen(true)}>
                <CustomIcon icon="trash" color="error" sx={{ fontSize: 16 }} />
                Remove tracking entries
            </Button>
            <ConfirmModal
                title="Delete device signals"
                body="Are you sure to remove all tracking entries of this device?"
                onClose={handleClose}
                open={open}
                onConfirm={handleDeleteDeviceSignals}
                isSubmitting={loading}
            />
        </>
    )
}

export default DeleteSignalsModal
